<template>
    <div class="setPassword">
        <table>
            <tr style="height:60px;">
                <td style="text-align:right;width:120px;padding-right:20px;">手机号码</td>
                <td style="text-align:left;">
                    <el-input style="width:218px;" disabled="true" v-model="user.phone" placeholder="请输入手机号"></el-input>
                </td>
            </tr>
            <tr style="height:60px;">
                <td style="text-align:right;width:120px;padding-right:20px;">手机验证码</td>
                <td style="text-align:left;" class="">
                    <el-input style="width:218px;" v-model="user.code" placeholder="请输入手机验证码"></el-input>
                    <button class="getcode cursorP" @click="getcode">{{msg}}</button>
                </td>
            </tr>
            <tr style="height:60px;">
                <td style="text-align:right;width:120px;padding-right:20px;">设置密码</td>
                <td style="text-align:left;">
                    <el-input style="width:391px;" v-model="user.password" placeholder="请设置新密码" show-password></el-input>
                </td>
            </tr>
            <tr style="height:60px;">
                <td style="text-align:right;width:120px;padding-right:20px;">确认密码</td>
                <td style="text-align:left;">
                    <el-input style="width:391px;" v-model="user.confirm_password" placeholder="请再次输入密码" show-password></el-input>
                </td>
            </tr>
            <tr>
                <td></td>
                <td><button @click="setPwd" class="setPwd cursorP">提交</button></td>
            </tr>
        </table>
    </div>
</template>
<script>
import { set_pwd } from '@/utils/Api/userList'
import { sms } from '@/utils/Api/doLogin'
import { removeToken } from '@/utils/auth'
export default {
    name:'setPassword',
    data(){
        return{
            user:{},
            msg: '获取验证码',
            codeTimer:null,
            times: 60
        }
    },
	created(){
		this.user.phone = this.userPhone
	},
	computed:{
		userPhone(){
			return this.$store.getters.personal.phone
		}
	},
    methods:{
        async getcode(){
            if(!this.user.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.user.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(this.codeTimer){
                this.$message.warning(`请在${this.times}后重新发送！`)
                return
            }
            this.codeTimer = setInterval(() => {
                this.times--
                this.msg = `${this.times}后重新发送`
                if(this.times < 1){
                    clearInterval(this.codeTimer)
                    this.codeTimer = null
                    this.msg = '获取验证码'
                    this.times = 60
                }
            },1000)
            try{
                let {res} = await sms({phone: this.user.phone})
                if(!res){
                    this.$message.success('验证码发送成功！')
                    return
                }
                this.$message.error('验证码发送失败')
                clearInterval(this.codeTimer)
                this.codeTimer = null
                this.msg = '获取验证码'
                this.times = 60
            }catch(e){console.log(e)}
        },
        async setPwd(){
            if(!this.user.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.user.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(!this.user.code){
                this.$message.warning('请填写验证码')
                return
            }
            if(!this.user.password){
                this.$message.warning('请填写密码')
                return
            }
            if(!this.user.confirm_password){
                this.$message.warning('请再次填写密码')
                return
            }
            if(this.user.password != this.user.confirm_password){
                this.$message.warning('两次密码输入不一致')
                return
            }
            let {res} = await set_pwd(this.user)
            if(!res){
                this.user = {}
                this.$message.success('密码重置成功！')
                removeToken()
                this.$store.commit('user/token','')
                this.$store.commit('user/personal',{})
                setTimeout(()=>{
                    this.$router.replace('/')
                },1500)
            }
            console.log(res)
        }
    }
}
</script>
<style lang="scss" scoped>
table{
    margin-top: 40px;
}
.setPassword{
    .getcode{
       min-width: 101px;
        height: 40px;
        opacity: 1;
        background: #47d7e3;
        color: #fff;
        margin-left: 15px;
        font-size: 16px;
    }
    .setPwd{
        width: 79px;
        height: 33px;
        opacity: 1;
        background: #ff9500;
        border-radius: 2px;
        font-size: 15px;
        color: #FFFFFF;
        float: left;
    }

}
</style>